import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import * as projectStyles from "../components/project.module.css"
import { Link } from "gatsby"
import Carousel from "react-bootstrap/Carousel"

const PlutoMarkePage = () => (
  <div>
    <Seo title="Marke für Pluto Technologies Limited - Altergott Studios" lang="de" description="Ein neues Corporate Design wurde für das Startup Pluto Technologies Limited erstellt, um die Markenwerte von Häuslichkeit, Bequemlichkeit, Kontrolle und technologischer Fortschrittlichkeit zu übermitteln." />

    <Layout>
      <Header />
      <div>
        <div className={projectStyles.carouselWrapper}>
          <Carousel className={projectStyles.carousel}>
            <Carousel.Item>
              <div className="slide five"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide six"></div>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className={projectStyles.projectGrid}>
          <div>
            <h3 className={projectStyles.uppercase}>Kunde</h3>

            <p>Pluto Technologies Limited (Mitgründer)</p>
            <Link
              to="https://pluto-website-483da2.webflow.io/"
              className={projectStyles.projectLink}
            >
              plutosmarthome.com
            </Link>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Projektbeschreibung</h3>

            <p>Leistungen: Erstellung eines Corporate Design</p>
            <p>
              Ein neues Corporate Design wurde für das Smart Home Startup Pluto Technologies Limited erstellt, um die Markenwerte von Häuslichkeit, Bequemlichkeit, Kontrolle und technologischer Fortschrittlichkeit zu übermitteln.
            </p>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Auswirkung</h3>

            <p>
              Das neue Corporate Design hat Kunden, Interessierten und dem Team eine Klarheit über die Markenidentität und Werte verschaffen.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default PlutoMarkePage